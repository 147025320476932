import {ApprovalTextEditorState} from './constants'

export const getShouldShowAutoApproveText = (
  approvalTextEditorState: ApprovalTextEditorState,
  isManualApproval: boolean,
) => {
  return approvalTextEditorState === ApprovalTextEditorState.unknown
    ? !isManualApproval
    : approvalTextEditorState === ApprovalTextEditorState.auto
}
