import isToday from 'date-fns/isToday'
import roundToNearestMinutes from 'date-fns/roundToNearestMinutes'
import addMonths from 'date-fns/addMonths'
import {setHMToDate, getScheduleFromCalendarForDate} from '@wix/table-reservations-lib/schedule'
import {BusinessSchedule} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {getScheduleForDate, getScheduleForMonth} from './businessSchedule'
import {generateTimeOptionsBasedOnDaySchedule, TimeOption} from './timeOptions'

const MAX_MONTHS_FORWARD_SEARCH = 12

const getNearestWorkingDateInner = ({
  businessSchedule,
  regionalSettings,
  timeZone,
  dateFrom = new Date(),
  recursionDeepness = 0,
}: {
  businessSchedule: BusinessSchedule
  regionalSettings?: string
  timeZone?: string | null
  dateFrom?: Date
  recursionDeepness?: number
}) => {
  let timeOption: TimeOption | undefined
  let nearestWorkingDayString: string | undefined

  const businessScheduleForMonth = getScheduleForMonth({
    businessSchedule,
    timeZone,
    dateFrom,
  })

  Object.keys(businessScheduleForMonth).some((dayString) => {
    const availableSlotsForDay = businessScheduleForMonth[dayString].schedule

    if (!availableSlotsForDay.length) {
      return false
    }

    const timeOptions = generateTimeOptionsBasedOnDaySchedule(
      new Date(dayString),
      availableSlotsForDay,
      regionalSettings,
    )

    if (timeOptions.length) {
      timeOption = timeOptions[0]
      nearestWorkingDayString = dayString

      return true
    }

    return false
  })

  if (nearestWorkingDayString && timeOption) {
    const {hours, minutes} = timeOption.data

    return setHMToDate(new Date(nearestWorkingDayString), hours, minutes)
  } else if (recursionDeepness < MAX_MONTHS_FORWARD_SEARCH) {
    return getNearestWorkingDateInner({
      businessSchedule,
      regionalSettings,
      timeZone,
      dateFrom: addMonths(dateFrom, 1),
      recursionDeepness: recursionDeepness + 1,
    })
  }

  return getNearestQuarterTime(new Date())
}

export const getNearestWorkingDate = ({
  businessSchedule,
  regionalSettings,
  timeZone,
}: {
  businessSchedule: BusinessSchedule
  regionalSettings?: string
  timeZone?: string | null
}) => {
  return getNearestWorkingDateInner({
    businessSchedule,
    regionalSettings,
    timeZone,
  })
}

export const filterDate = ({
  date,
  businessSchedule,
  regionalFormat,
  timeZone,
}: {
  date: Date
  businessSchedule: BusinessSchedule
  regionalFormat?: string
  timeZone?: string | null
}) => {
  const scheduleForDate = getScheduleForDate({date, businessSchedule, timeZone})
  const schedule = getScheduleFromCalendarForDate(date, scheduleForDate)

  if (isToday(date)) {
    const timeOptions = generateTimeOptionsBasedOnDaySchedule(date, schedule, regionalFormat)

    return !!timeOptions.length
  }

  return !!schedule?.length
}

export const getNearestQuarterTime = (date: Date) =>
  roundToNearestMinutes(date, {nearestTo: 15, roundingMethod: 'ceil'})

export const roundUpToNearestQuarterTime = (date: Date) => roundToNearestQuarterTime(date, 'up')
export const roundDownToNearestQuarterTime = (date: Date) => roundToNearestQuarterTime(date, 'down')

export const formatTwoDigitDate = (date: Date | undefined, locale: string = 'en'): string => {
  if (!date) {
    return ''
  }
  return new Intl.DateTimeFormat(locale, {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(date)
}

const roundToNearestQuarterTime = (date: Date, roundingDirection: 'up' | 'down') => {
  const coefficient = 1000 * 60 * 15
  const roundingMethod = roundingDirection === 'up' ? Math.ceil : Math.floor
  return new Date(roundingMethod(date.getTime() / coefficient) * coefficient)
}
