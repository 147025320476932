import {useRef, useState} from 'react'

export const useElementWidth = <Element extends HTMLElement>(dataHookFallback?: string) => {
  const ref = useRef<Element>(null)
  const [width, setWidth] = useState<number>()

  const readAndSetWidth = () => {
    if (ref?.current) {
      setWidth(ref.current.getBoundingClientRect()?.width)
    } else if (dataHookFallback) {
      const element = document.querySelector(`[data-hook="${dataHookFallback}"]`)
      setWidth(element?.getBoundingClientRect()?.width)
    }
  }

  return {
    ref,
    width,
    readAndSetWidth,
  }
}
