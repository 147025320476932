import {StyleParamType, wixColorParam, wixFontParam, createStylesParams} from '@wix/tpa-settings'

export type ButtonDefaultStylesParams = {
  buttonDefaultFont: StyleParamType.Font
  buttonDefaultColor: StyleParamType.Color
  buttonDefaultFillOpacityColor: StyleParamType.Color
  buttonDefaultBorderOpacityColor: StyleParamType.Color
  buttonDefaultBorderWidth: StyleParamType.Number
  buttonDefaultCornerRadius: StyleParamType.Number
}

export const buttonDefaultStylesParams = createStylesParams<ButtonDefaultStylesParams>({
  buttonDefaultFont: {
    type: StyleParamType.Font,
    inheritDesktop: false,
    getDefaultValue: wixFontParam('Body-M', {size: 16}),
  },
  buttonDefaultColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
  buttonDefaultFillOpacityColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  buttonDefaultBorderOpacityColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 1),
  },
  buttonDefaultBorderWidth: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
  buttonDefaultCornerRadius: {
    type: StyleParamType.Number,
    getDefaultValue: () => 0,
  },
})

export type ButtonHoverStylesParams = {
  buttonHoverFillOpacityColor: StyleParamType.Color
  buttonHoverBorderOpacityColor: StyleParamType.Color
  buttonHoverTextColor: StyleParamType.Color
}

export const buttonHoverStylesParams = createStylesParams<ButtonHoverStylesParams>({
  buttonHoverFillOpacityColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  buttonHoverBorderOpacityColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-8', 0.7),
  },
  buttonHoverTextColor: {
    type: StyleParamType.Color,
    getDefaultValue: wixColorParam('color-1', 1),
  },
})
