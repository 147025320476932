import {useEnvironment, useBi} from '@wix/yoshi-flow-editor'
import {useEffect, useMemo, useState} from 'react'
import {useSettings} from '@wix/tpa-settings/react'
import isEqual from 'lodash/isEqual'

import {useReservationAddonStorage} from '../storage'
import {PageType, getLogger} from '../../../utils/getLogger'
import {
  getEnabledReservationLocations,
  getInitialReservationLocation,
} from '../../../utils/reservationLocation'
import {settingsParams} from '../settingsParams'
import {DisplayConfig} from '../Settings/Tabs/Display/settingsParams'
import {useGetLayoutSize} from '../../../components-shared/LayoutSizeProvider'
import {useApproveTextView} from '../../../utils/useApproveTextView'

export const useHooks = () => {
  const {reservationLocations, approvalTextEditorState} = useReservationAddonStorage()
  const {isPreview} = useEnvironment()
  const bi = useBi()
  const logger = getLogger(bi)
  const settings = useSettings()
  const {layoutSize, containerWidth} = useGetLayoutSize()

  //  settings.get(settingsParams.displayConfig) returns new object on every call
  const displayConfigRaw = settings.get(settingsParams.displayConfig) as DisplayConfig
  const [displayConfig, setDisplayConfig] = useState(displayConfigRaw)

  useEffect(() => {
    setDisplayConfig((prevDisplayConfig) =>
      isEqual(prevDisplayConfig, displayConfigRaw) ? prevDisplayConfig : displayConfigRaw,
    )
  }, [displayConfigRaw])

  const enabledReservationLocations = useMemo(() => {
    let enabledLocations = getEnabledReservationLocations(reservationLocations)

    if (displayConfig.enabledLocations) {
      enabledLocations = enabledLocations.filter(
        (location) => displayConfig.enabledLocations?.includes(location.id!) ?? false,
      )
    }
    if (enabledLocations.length === 0 && reservationLocations[0]) {
      enabledLocations = [reservationLocations[0]]
    }

    return enabledLocations
  }, [reservationLocations, displayConfig])

  const {shouldShowAutoApproveText} = useApproveTextView(
    enabledReservationLocations,
    approvalTextEditorState,
  )

  const selectedReservationLocationId = getInitialReservationLocation(
    enabledReservationLocations,
  )?.id

  const shouldShowNoLocations = !selectedReservationLocationId

  const currentReservationLocation = reservationLocations.find(
    ({id}) => id === selectedReservationLocationId,
  )

  useEffect(() => {
    logger.isLoaded({
      isAddOn: true,
      isPreview,
      pageType: PageType.widget,
    })
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return {
    layoutSize,
    containerWidth,
    enabledReservationLocations,
    shouldShowNoLocations,
    shouldShowAutoApproveText,
  }
}
