import {dataHooks} from '@wix/data-hooks'

import {LayoutBreakpointsConfig} from '../../../components-shared/LayoutSizeProvider/types'

export const LAYOUT_BREAKPOINTS_START: LayoutBreakpointsConfig = {
  XS: 320,
  S: 410,
  M: 681,
  L: 1201,
}

export const reservationAddOnDataHooks = dataHooks<{
  submitButton: {}
}>('reservation-addon')

export const getReservationAddOnDataHookWithId = (compId: string) =>
  reservationAddOnDataHooks.submitButton({id: compId})
