import {createSettingsParams, SettingsParamType} from '@wix/tpa-settings'

export interface DisplayConfig {
  showAllLocations: boolean
  enabledLocations?: string[]
}

export type DisplaySettingsParams = {
  showTitle: SettingsParamType.Boolean
  showDescription: SettingsParamType.Boolean
  showFormFieldLabels: SettingsParamType.Boolean
  displayConfig: SettingsParamType.Object
}

export const displaySettingsParams = createSettingsParams<DisplaySettingsParams>({
  showTitle: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showDescription: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  showFormFieldLabels: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  displayConfig: {
    type: SettingsParamType.Object,
    getDefaultValue: (): DisplayConfig => ({showAllLocations: true}),
  },
})
