import {dateTimeFormat} from 'wix-design-systems-locale-utils'

import {DEFAULT_REGION_SETTINGS} from './constants'
import {parseLocaleFromBM} from './locale'

export const getShortTime = (
  date: Date = new Date(),
  regionalSettings: string = DEFAULT_REGION_SETTINGS,
): string => dateTimeFormat.getShortTime(parseLocaleFromBM(regionalSettings), date) ?? ''

export const getMediumDate = (
  date: Date = new Date(),
  regionalSettings: string = DEFAULT_REGION_SETTINGS,
): string => dateTimeFormat.getMediumDate(parseLocaleFromBM(regionalSettings), date) ?? ''

export const getLongDate = (
  date: Date = new Date(),
  regionalSettings: string = DEFAULT_REGION_SETTINGS,
) =>
  date.toLocaleDateString(regionalSettings, {
    weekday: 'long',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  })
