import React, {PropsWithChildren} from 'react'
import {TPAComponentsProvider} from 'wix-ui-tpa/TPAComponentsConfig'
import {useEnvironment} from '@wix/yoshi-flow-editor'

import {classes} from './styles.st.css'

export const RtlProvider: React.FC<PropsWithChildren> = ({children}) => {
  const {isRTL} = useEnvironment()

  return (
    <TPAComponentsProvider value={{rtl: isRTL}}>
      <div className={classes.rtlWrapper} dir={isRTL ? 'rtl' : undefined}>
        {children}
      </div>
    </TPAComponentsProvider>
  )
}
