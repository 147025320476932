import {StylesParamsType} from '../../types/createStylesParams'
import {
  buttonDefaultStylesParams,
  buttonHoverStylesParams,
  ButtonDefaultStylesParams,
  ButtonHoverStylesParams,
} from '../../components-shared/Settings/Button/stylesParams'
import {
  formLineStylesParams,
  formBoxStylesParams,
  FormLineStylesParams,
  FormBoxStylesParams,
} from '../../components-shared/Settings/FormDesign/stylesParams'

import {generalStyleParams, GeneralStylesParams} from './Settings/Tabs/Design/General/stylesParams'
import {layoutStylesParams, LayoutStylesParams} from './Settings/Tabs/Layout/stylesParams'

export type StylesParams = GeneralStylesParams &
  ButtonDefaultStylesParams &
  ButtonHoverStylesParams &
  FormLineStylesParams &
  FormBoxStylesParams &
  LayoutStylesParams

export const stylesParams: StylesParamsType<StylesParams> = {
  ...generalStyleParams,
  ...formLineStylesParams,
  ...formBoxStylesParams,
  ...buttonDefaultStylesParams,
  ...buttonHoverStylesParams,
  ...layoutStylesParams,
}

export default stylesParams
