import React, {FC, useEffect, useRef, useState} from 'react'
import {TextField, Spinner, DatePickerInputProps} from 'wix-ui-tpa/cssVars'
import {Calendar} from '@wix/wix-ui-icons-common/on-stage'

import {formatTwoDigitDate} from '../../utils/date'

type LazyDatePickerProps = DatePickerInputProps & {
  datePickerClass: string
  dateTextFieldClass: string
  dateTextFieldDataHook: string
  showFormFieldLabels?: boolean
}

export const LazyDatePicker: FC<LazyDatePickerProps> = ({
  value: selectedDate,
  label,
  locale,
  datePickerClass,
  dateTextFieldClass,
  dateTextFieldDataHook,
  showFormFieldLabels = true,
  inputTheme,
  ...rest
}) => {
  const [showDatePicker, setShowDatePicker] = useState(false)
  const [isDatePickerLoading, setIsDatePickerLoading] = useState(false)
  const [textFieldClicked, setTextFieldClicked] = useState(false)
  const [DatePickerInputComponent, setDatePickerInputComponent] =
    useState<React.ComponentType<DatePickerInputProps> | null>(null)
  const datePickerInputRef = useRef<HTMLDivElement | null>(null)

  // load the DatePickerInput component on mount
  useEffect(() => {
    import(/* webpackChunkName: "DatePickerInput" */ 'wix-ui-tpa/cssVars')
      .then((module) => {
        setDatePickerInputComponent(() => module.DatePickerInput)
        setShowDatePicker(true)
      })
      .finally(() => setIsDatePickerLoading(false))
  }, [])

  useEffect(() => {
    if (
      showDatePicker &&
      DatePickerInputComponent &&
      datePickerInputRef.current &&
      textFieldClicked
    ) {
      // If text field clicked before the DatePicker was loaded, open it immediately after loading
      const datePickerInput = datePickerInputRef.current.querySelector('input')
      datePickerInput?.click()
      datePickerInput?.focus()
    }
  }, [showDatePicker, DatePickerInputComponent, textFieldClicked])

  const handleTextFieldClick = () => {
    setTextFieldClicked(true)
    setIsDatePickerLoading(true)
  }

  return (
    <>
      {showDatePicker && DatePickerInputComponent ? (
        <div ref={datePickerInputRef}>
          <DatePickerInputComponent
            locale={locale}
            className={datePickerClass}
            inputTheme={inputTheme}
            excludePastDates
            aria-label={label}
            label={showFormFieldLabels ? label : undefined}
            value={selectedDate}
            inputWidth="100%"
            removeClearButton={true}
            zIndex={9999}
            {...rest}
          />
        </div>
      ) : (
        <TextField
          suffix={isDatePickerLoading ? <Spinner diameter={20} /> : <Calendar />}
          aria-label={label}
          label={showFormFieldLabels ? label : undefined}
          data-hook={dateTextFieldDataHook}
          onClick={handleTextFieldClick}
          onFocus={handleTextFieldClick}
          value={formatTwoDigitDate(selectedDate, locale)}
          className={dateTextFieldClass}
          theme={inputTheme}
          readOnly={true}
        />
      )}
    </>
  )
}
