import React from 'react'
import {Text, TextTypography} from 'wix-ui-tpa/cssVars'
import {useTranslation} from '@wix/yoshi-flow-editor'

import {LayoutSize} from '../LayoutSizeProvider/types'

import {classes, st} from './NotAcceptingBanner.st.css'
import {notAcceptingBannerDataHooks} from './constants'

type NotAcceptingBannerProps = {
  layoutSize: LayoutSize
  className?: string
}

export const NotAcceptingBanner: React.FC<NotAcceptingBannerProps> = ({layoutSize, className}) => {
  const {t} = useTranslation()

  const layoutClass =
    layoutSize === LayoutSize.XS || layoutSize === LayoutSize.S ? classes.packed : ''
  return (
    <div
      className={st(classes.notAcceptingWrapper, layoutClass, className)}
      data-hook={notAcceptingBannerDataHooks.root()}
    >
      <Text
        className={classes.notAcceptingTitle}
        tagName="H2"
        typography={TextTypography.smallTitle}
      >
        {t('uou-reservations.reservations.not-accepting-title')}
      </Text>
      {/* TODO: Remove old i18n key/value for contact by phone (uou-reservations.reservations.not-accepting-subtitle) */}
      <Text className={classes.bodyText}>
        {t('uou-reservations.reservations.not-accepting-contact-by-phone')}
      </Text>
    </div>
  )
}
