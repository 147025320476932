import {zonedTimeToUtc} from '@wix/table-reservations-lib/timezone'
import addMinutes from 'date-fns/addMinutes'
import addHours from 'date-fns/addHours'
import addDays from 'date-fns/addDays'
import {
  Unit,
  NoticePeriod,
  SpecialHourPeriod,
} from '@wix/ambassador-table-reservations-v1-reservation-location/types'

import {roundUpToNearestQuarterTime, roundDownToNearestQuarterTime} from './date'

export const convertReservationNoticeToSpecialHourPeriod = (
  noticePeriod: NoticePeriod,
  timeZone?: string | null,
): SpecialHourPeriod => {
  const now = zonedTimeToUtc(new Date(), timeZone)

  const startDate = roundDownToNearestQuarterTime(now)
  const endDateWithNoticePeriod = addNoticePeriod(now, noticePeriod)
  const endDate = roundUpToNearestQuarterTime(endDateWithNoticePeriod)

  return {
    comment: 'minimumReservationNotice',
    isClosed: true,
    startDate: startDate.toISOString(),
    endDate: endDate.toISOString(),
  }
}

const addNoticePeriod = (date: Date, {number, unit}: NoticePeriod) => {
  if (number && unit) {
    switch (unit) {
      case Unit.MINUTES:
        return addMinutes(date, number)
      case Unit.HOURS:
        return addHours(date, number)
      case Unit.DAYS:
        return addDays(date, number)
    }
  }

  return date
}
